export const fr = {
    connexion: "Connexion",
    username: "Email",
    telephone: "Téléphone",
    code: "Code à 6 chiffres",
    password: "Mot de passe",
    seconnecter: "Se connecter",
    send: "Envoyer",
    Code_incorrect: "Code incorrect",
    champObligatoire: "Ce champ doit être renseigné.",
    transactionEffectuer: "Transaction effectuée avec succès.",
    apiDesactive: "Cet API est desactivé. Merci de contacter l'administrateur.",
    erreurCLE: "Erreur du serveur. Verifier votre CLE.",
    erreurServeur: "Erreur du serveur. Merci de nous contacter si l'erreur persiste",
    noAccess: "Accès refusé. Veuillez vérifier vos identifiants",
    erreurVerification: "Erreur du serveur, vérifié que toutes les données sont correctes. Merci de nous contacter si l'erreur persiste",
    transctionAnnulerBack: "Transaction annulée",
    transctioneffectuerBack: "Transaction effectuée",
    vousEteSurLePoinDeFaire: "Vous êtes sur le point de faire une transaction de",
    chez: "chez",
    utiliserSevobPay: "Dans le cas où votre moyen de paiement n'est pas disponible, nous vous conseillons d'utiliser Sevob-Pay car il est universel.",
    emailNoValid: "Cet email n'est pas valide",
    connecteEnTant: "Connecté en tant que:",
    soldeActuelle: "Solde actuel:",
    payerMaintenant: "Payer maintenant",
    soldeInsufisant: "Votre solde n'est pas suffisant pour cette transaction",
    commentSEVOBPayMarche: "Comment Sevob-Pay marche ?",
    siteWeb: "Site web",
    accederAuSite: "Accédez au site web de SEVOB",
    creationCompte: "Création du compte",
    connectezVousAVotreCompte: "Connectez-vous si vous possédez déjà un compte. Sinon, créez gratuitement votre compte.",
    Recharge: "Recharge",
    DansVotreCompte: "Dans votre compte, cliquez sur",
    TypeRecharge: "Type de recharge possible",
    IlExistePlusierFacon: "Il existe plusieurs façons de recharger, comme par",
    nousContacter: "Nous contacter",
    whatsappAppel: "WhatsApp ou Appel",
    Français: "Français",
    Anglais: "Anglais",
}