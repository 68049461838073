import { Grid } from '@material-ui/core';
import { Button, Form, Input } from 'antd';
import { encode as btoa } from 'base-64';
import { useState } from 'react';
import { withTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from '../../Constant';
import Service from '../../service';

function SevobPay(props2) {
    const {t} = props2.props;
    const [response, setReponse] = useState({})
    const [token, setToken] = useState("")
    const onFinish = (values) => {
        props2.props.changeSpinnerSecondaire(true)
        let token2 = btoa(values.email + ':' + values.password);
        setToken(token2)
        Service.loginApi(token2).then(
          async (response) => {
            if(response.data.code === "200") {
                setReponse(response.data.data)
                if(parseFloat(response.data.data.solde) < parseFloat(props2.props.data.montant)){
                    props2.props.changeReponse({type: Constant.error, message: t("soldeInsufisant")})
                }
            } else {
                props2.props.changeReponse({type: Constant.error, message: t(response.data.message)})
            }
            props2.props.changeSpinnerSecondaire(false)
          }, error => {
                props2.props.changeSpinnerSecondaire(false)
                props2.props.changeReponse({type: Constant.error, message: t("noAccess")})
          }
        )
      };

      const payerMaintenant = () => {
        props2.props.changeSpinnerSecondaire(true)
        props2.props.data.notif_token = new Date().getTime()
        Service.SevobPay(props2.props.data, token).then(
            async (response) => {
                props2.props.changeReponse({
                    type: Constant.success,
                    message: ""
                })
              props2.props.changeSpinnerSecondaire(false)
            }, error => {
                console.log(error);
                  props2.props.changeSpinnerSecondaire(false)
                  props2.props.changeReponse({type: Constant.error, message: t("erreurVerification")})
            }
        )
      }

    return (
        <>
        <ToastContainer position='top-center'/>
        {response.nom === undefined
        ?
            <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
                <Grid item sm={8} md={8} lg={8} xl={8}>
                    <Form
                        name="basic"
                        labelCol={{
                        span: 24,
                        }}
                        wrapperCol={{
                        span: 24,
                        }}
                        style={{
                        maxWidth: 600,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={t("username")}
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: t("emailNoValid"),
                                },
                                {
                                required: true,
                                message: t("champObligatoire"),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t("password")}
                            name="password"
                            rules={[
                                {
                                required: true,
                                message: t("champObligatoire"),
                                },
                            ]}
                        >
                        <Input.Password />
                        </Form.Item>

                        <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                        >
                        <Button type='primary' htmlType="submit" >
                            {t("seconnecter")}
                        </Button>
                        </Form.Item>
                    </Form>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
            </Grid>
        :
            <div>
                <div style={{float: 'left', marginTop: -20, marginLeft: 10, lineHeight: 0.5}}>
                    <p>{t("connecteEnTant")} <strong>{response.nom}</strong></p>
                    <p>{t("soldeActuelle")} <strong style={{color: parseFloat(response.solde) < parseFloat(props2.props.data.montant) ? "red" : "green"}}>{response.solde.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} {Constant.devis}</strong></p>
                </div>
                {/* Montant */}
                <p style={{float: 'right', fontWeight: 'bold', margin: 0}}><strong style={{backgroundColor: "green", color: " white", padding: 10, borderBottomLeftRadius: 5}}>{props2.props.data.id !== undefined && props2.props.data.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} {Constant.devis}</strong></p>
                <br/>
                {parseFloat(response.solde) >= parseFloat(props2.props.data.montant) 
                &&
                    <div>
                        <div style={{marginTop:40, textAlign: 'center', marginLeft: 20, marginRight: 20}}>
                            <p style={{fontWeight: "bold", color: 'red'}}>{t("vousEteSurLePoinDeFaire")} {props2.props.data.id !== undefined && props2.props.data.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} {Constant.devis} {t("chez")} {props2.props.data.id !== undefined && props2.props.data.nomentreprise}.</p>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <Button type='primary' onClick={(e)=>payerMaintenant()} >
                                {t("payerMaintenant")}
                            </Button>
                        </div>
                    </div>
                }
            </div>
        }
        </>
    );
}
    export default withTranslation()(SevobPay);