import { Grid } from "@material-ui/core";
import { Button, Modal, Steps } from "antd";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Constant } from "../../Constant";
import SEVOBMoney from "../../assets/SEVOB-Pay.png";
import OrangeMoney from "../../assets/orangemoney.png";

function Accueil(props2) {
    const {t} = props2.props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [current, setCurrent] = useState(0);


    let typePaiment= [
        {
            nom: Constant.OrangeMoney,
            logo: OrangeMoney
        },
        {
            nom: Constant.SEVOBPay,
            logo: SEVOBMoney
        },
    ]

    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };


        const onChange = (value) => {
            setCurrent(value);
        };

    return (
        <>
            {props2.props.reponse.type !== Constant.error &&
                <>
                    <div>
                        {/* Mode test uniquement */}
                        {props2.props.data.id !== undefined && props2.props.data.mode === "TEST" &&
                            <p style={{float: 'left', fontWeight: 'bold', margin: 0}}><strong style={{backgroundColor: "red", color: " white", padding: 10, borderBottomRightRadius: 5}}>MODE TEST</strong></p>
                        }
                        {/* Montant */}
                        <p style={{float: 'right', fontWeight: 'bold', margin: 0}}><strong style={{backgroundColor: "green", color: " white", padding: 10, borderBottomLeftRadius: 5}}>{props2.props.data.id !== undefined && props2.props.data.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} {Constant.devis}</strong></p>
                    </div>
                    <br/>
                    {/* Message de transacation */}
                    <div style={{marginTop:40, textAlign: 'center', marginLeft: 20, marginRight: 20}}>
                        <p style={{fontWeight: "bold"}}>{t("vousEteSurLePoinDeFaire")} {props2.props.data.id !== undefined && props2.props.data.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} {Constant.devis} {t("chez")} {props2.props.data.id !== undefined && props2.props.data.nomentreprise}.</p>
                        <p style={{fontWeight: "bold", color: 'red'}}>{t("utiliserSevobPay")}</p>
                        <Button type='primary' ghost onClick={(e)=>showModal()} >
                            {t("commentSEVOBPayMarche")}
                        </Button>
                    </div>
                </>
            }
            <div style={{textAlign: 'center', marginTop: 50}}>
                <Grid container>
                    {typePaiment.map((type, index) => {
                        return <Grid key={index+ "a"} item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <img onClick={(e)=> { if(!props2.props.spinner.principal && !props2.props.spinner.secondaire && props2.props.reponse.type === "") props2.Verification(type)}} src={type.logo} width={100} height={100} style={{cursor: (!props2.props.spinner.principal && !props2.props.spinner.secondaire && props2.props.reponse.type === "") ? 'pointer' : "not-allowed"}}/>
                                </Grid>
                    })}
                </Grid>
            </div>

            <Modal footer={null} title={t("commentSEVOBPayMarche")} open={isModalOpen} onCancel={handleCancel}>
                <div>
                    <Steps
                        current={current}
                        onChange={onChange}
                        direction="vertical"
                        items={[
                        {
                            title: t("siteWeb"),
                            description: <div>{t("accederAuSite")} <a href="https://www.sevob.net/">www.sevob.net</a></div>,
                        },
                        {
                            title: t("creationCompte"),
                            description: t("connectezVousAVotreCompte"),
                        },
                        {
                            title: t("Recharge"),
                            description: <div>{t("DansVotreCompte")} <strong>Recharger mon compte</strong></div>,
                        },
                        {
                            title: t("TypeRecharge"),
                            description: <div>
                                {t("IlExistePlusierFacon")}:<br/>
                                <strong style={{color: 'red'}}>Western union</strong>, &nbsp;
                                <strong style={{color: 'red'}}>Ria</strong>, &nbsp;
                                <strong style={{color: 'red'}}>MoneyGram</strong>, &nbsp;
                                <strong style={{color: 'red'}}>OrangeMoney</strong>, &nbsp;
                                <strong style={{color: 'red'}}>MoovMoney</strong>, &nbsp;
                                <strong style={{color: 'red'}}>SamaMoney</strong>, &nbsp;
                                <strong style={{color: 'red'}}>WaveMoney</strong>, &nbsp;
                                <strong>Etc...</strong>
                            </div>,
                        },
                        {
                            title: t("nousContacter"),
                            description: <div>
                                {t("whatsappAppel")}:  <strong>+223 72 20 81 20</strong>
                                <br/>
                                E-mail: <strong>pierrediarra30@gmail.com</strong>
                            </div>,
                        },
                        ]}
                    />
                </div>
            </Modal>
        </>
    );
}
    export default withTranslation()(Accueil);