export const ml = {
    connexion: "Sɛgɛsɛgɛli",
    jean1721: "Yuhana 17:21",
    jean1721verset: "Walisa u bɛɛ ka kɛ kelen ye, ne Fa, i ko e bɛ ne la ani ne bɛ i la cogo min na, u fana ka kɛ an na, walisa diɲɛ ka da a la ko i ye ne ci.",
    username: "Sɛgɛsɛgɛli tɔgɔ",
    password: "Sɛgɛsɛgɛli gundo",
    seconnecter: "Ka don",
    motdepasseoublier: "I ɲinan na gundo kɔ wa ?",
    accesRefuse: "Son ma kɛ i ka ko ɲinita ma. I ka kunnafoniw sɛgɛsɛgɛ ka sifilɛ",
    dashboard: "Kunafoni so ba",
    Assemblees: "Djɛ kulu",
    Departements: "Baarada",
    Membres: "Mogow",
    Mariages: "Furuw",
    Activites: "Ko bolodalenw",
    Projets: "Ko bontaw",
    Comptabilites: "Wari kow",
    SMS: "Kunnafoni",
    Statistiques: "Djabiw ɲinili",
    newAssemble: "Djɛ kulu kura",
    champObligatoire: "Ni sɛnbin yɔrɔ ye diagoya ye",
    logo: "Dja",
    nom: "Tɔgɔ",
    adresse: "Sɔrɔ yɔrɔ",
    Datecreation: "A sigi don",
    dateajout: "Farakan don",
    longitude: "kundama fɔlɔ",
    latitude: "kundama filana",
    information: "Kunafoni wɛrɛw",
    ajouter: "Ka fara tɔw kan",
    modifier: "Ka yɛlɛma don a la",
    supprimer: "Ka a bɔ a la",
    annuler: "Ka a dabila",
    ajoutSuccess: "A farala tɔw kan",
    modificationSuccess: "Yɛlɛma kɛra",
    suppressionSuccess: "A bɔra ye",
    erreurServeur: "Gɛlɛya dɔ sɔrɔla bara kɛ tuma la. I be se ka an kunnafoniya ni gɛlɛya ma ta.",
    Statut: "Tiogoya",
    active: "Bɛse ka bara kɛ",
    desactive: "Tɛse ka bara kɛ",
    activer: "A ka bara kɛ",
    desactiver: "Ka bali bara la",
    suppressionConfirm: "I ka ko kɛta sɛbɛntiya ?",
    Action: "Minuw bɛ se ka kɛ",
    lierA: "A tugulen bɛ ni la",
    Schema: "Dja",
    table: "Tablo",
    parent: "Fa",
    enfant: "Den",
    chercher: "Ka ɲinita kɛ",
    tout: "A bɛ",
    du: "Daminɛ",
    au: "laban",
    orientation: "Kɛ tiogo",
    Chemin: "Sira",
    Espace: "Danfara",
    newDepartements: "Baarada coura",
}