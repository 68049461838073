import { withTranslation } from "react-i18next";

import { useState } from "react";
import { Constant } from "../Constant";
import Service from "../service";
import Accueil from "./contentEnfant/accueil";
import SevobPay from "./contentEnfant/sevobPay";
import TestMode from "./contentEnfant/testMode";

function Content(props) {
    const {t} = props;
    const [choix, setChoix] = useState(Constant.choixAccueil);

    const Verification = (paiementMode) => {
        props.data.typepaiement = paiementMode.nom
        props.data.commandeid = new Date().getTime()
        // Pour le test
            if(props.data.mode === Constant.modeTest) {
                setChoix(Constant.modeTest)
            } 
        // Pour le REEL
            else {
                // Pour OrangeMoney
                    if(paiementMode.nom === Constant.OrangeMoney) {
                        OrangeMoney()
                    }
                // Pour SEVOB-Pay
                    else if(paiementMode.nom === Constant.SEVOBPay) {
                        setChoix(paiementMode.nom)
                    }
            }
    }

    const OrangeMoney = () => {
        props.changeSpinnerSecondaire(true)
        Service.OrangeMoney(props.data).then(
            async (response) => {
                window.open(response.data, "_self");
                props.changeSpinnerSecondaire(false)
            }, error => {
                props.changeSpinnerSecondaire(false)
                props.changeReponse({type: Constant.error, message: t("erreurServeur")})
            })
    }

    return (
        <div style={{marginTop: 10, marginBottom: 30}}>
        {/* Pour Accueil */}
            {choix===Constant.choixAccueil &&
                <Accueil Verification={Verification} props={props}/>
            }
        {/* Pour le mode TEST */}
            {choix===Constant.modeTest &&
                <TestMode props={props}/>
            }
        {/* Pour SEVOB Pay */}
            {choix===Constant.SEVOBPay &&
                <SevobPay props={props}/>
            }
        </div>
    );
}
    export default withTranslation()(Content);