export const en = {
    connexion: "Login",
    username: "Email",
    telephone: "Phone",
    code: "6-digit code",
    password: "Password",
    seconnecter: "To log in",
    send: "Send",
    Code_incorrect: "Incorrect code",
    champObligatoire: "This field is required.",
    transactionEffectuer: "Transaction completed successfully.",
    apiDesactive: "This API is disabled. Please contact the administrator.",
    erreurCLE: "Server error. Check your KEY.",
    erreurServeur: "Server error. Please contact us if the error persists",
    noAccess: "Access denied. Please verify your credentials",
    erreurVerification: "Server error, verified all data is correct. Please contact us if the error persists",
    transctionAnnulerBack: "Transaction canceled",
    transctioneffectuerBack: "Transaction completed",
    vousEteSurLePoinDeFaire: "You are about to make a transaction of",
    chez: "at",
    utiliserSevobPay: "In case your payment method is not available, we advise you to use Sevob-Pay as it is universal.",
    emailNoValid: "This email is not valid",
    connecteEnTant: "Logged in as:",
    soldeActuelle: "Current balance:",
    payerMaintenant: "Pay now",
    soldeInsufisant: "Your balance is not sufficient for this transaction",
    commentSEVOBPayMarche: "How does Sevob-Pay work ?",
    siteWeb: "Website",
    accederAuSite: "Access the SEVOB website",
    creationCompte: "Account creation",
    connectezVousAVotreCompte: "Log in if you already have an account. Otherwise, create your account for free.",
    Recharge: "Recharge",
    DansVotreCompte: "In your account, click",
    TypeRecharge: "Possible type of recharge",
    IlExistePlusierFacon: "There are several ways to recharge, such as",
    nousContacter: "Contact us",
    whatsappAppel: "WhatsApp or Call",
    Français: "French",
    Anglais: "English",
}