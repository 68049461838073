import { withTranslation } from "react-i18next";
import { Constant } from "../Constant";
import logoAvecText from "./../assets/logo.png";

function Footer(props) {
    const {t} = props;
    return (
        <div style={{borderTop: '1px solid grey'}}>
            <div style={{margin: 20, float: 'left'}}>
                {props.reponse.type === Constant.success
                ?
                    <a href={props.data.id !== undefined ? props.data.returnurl : ""} style={{backgroundColor: 'green', color: 'white', textDecoration: 'none', padding: 10, borderRadius: 10, paddingLeft: 20, paddingRight: 20}}>Retour</a>
                :
                    <a href={props.data.id !== undefined ? props.data.cancelurl : ""} style={{backgroundColor: 'red', color: 'white', textDecoration: 'none', padding: 10, borderRadius: 10, paddingLeft: 20, paddingRight: 20}}>Annuler</a>
                }
            </div>
            <div style={{float: 'right', marginRight: 10}}>
                <img width={150} height={60} src={logoAvecText}/>
            </div>
        </div>
    );
}
    export default withTranslation()(Footer);