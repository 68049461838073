import { Grid } from '@material-ui/core';
import { Select, Space } from 'antd';
import { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import './App.css';
import { Constant } from "./Constant";
import fr from "./assets/fr.png";
import ml from "./assets/ml.png";
import us from "./assets/us.png";
import Content from './pierre/content';
import Footer from './pierre/footer';
import Header from './pierre/header';
import Service from "./service";


function App(props) {
  const {t} = props;
  const [spinner, setSpinner] = useState({
    principal: true,
    secondaire: false,
  })
  const [data, setData] = useState({});
  const [reponse, setReponse] = useState({
    type: "",
    message: ""
  });

  useEffect(()=>{
    (()=> {
      const queryParams = new URLSearchParams(window.location.search);
      getData(queryParams.get('c'))
    })();
  }, [])

  const getData = (codeForLink) => {
    Service.getData(codeForLink).then(
      async (response) => {
        setSpinner({...spinner, principal: false})
        if(response.data.data !== null && response.data.data !== undefined && response.data.data !== "") {
          setData(response.data.data)
        } else {
          setReponse({...reponse, type: Constant.error, message: t("erreurServeur")})
        }
      }, error => {
        setSpinner({...spinner, principal: false})
        setReponse({...reponse, type: Constant.error, message: t("erreurServeur")})
      })
  }

  const changeReponse = (data) => {
    setReponse(data)
  }
  const changeSpinnerSecondaire = (value) => {
    setSpinner({...spinner, secondaire: value})
  }

  
  return (
    <>
      <Grid container style={{marginTop: 100,}}>
        <Grid item sm={1} md={2} lg={4} xl={4}></Grid>
        <Grid item xs={12} sm={10} md={8} lg={4} xl={4}>
          <div>
            <Select
              defaultValue={props.i18n.language}
              onChange={(e)=>props.i18n.changeLanguage(e)}
              labelRender={(option) => {
                return <Space>
                          <img width={25} height={15} src={option.value === Constant.ml && ml || option.value === Constant.fr && fr || option.value === Constant.en && us }/> {option.label}
                        </Space>
              }}
              dropdownStyle={{zIndex: 2000}}
                      options={[
                        // { value: Constant.ml, label: "Bambara", img: ml },
                        { value: Constant.fr, label: t("Français"), img: fr },
                        { value: Constant.en, label: t("Anglais"), img: us },
                      ]}
              optionRender={(option) => (
                <Space>
                  <img width={25} height={15} src={option.data.img}/> {option.data.label}
                </Space>
              )}
            />
          </div>
          <div style={{width: "100%", height: "100%", backgroundColor: "white", borderRadius: 10, border: "1px solid grey", boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"}}>          
              <Header spinner={spinner} data={data} reponse={reponse}/>
              {reponse.type !== Constant.success
              ?
                <Content spinner={spinner} data={data} reponse={reponse} changeReponse={changeReponse} changeSpinnerSecondaire={changeSpinnerSecondaire}/>
              :
                <h1 style={{textAlign: 'center', color: 'green'}}>{t("transactionEffectuer")}</h1>
              }
              <Footer spinner={spinner} data={data} reponse={reponse}/>
          </div>
        </Grid>
        <Grid item sm={1} md={2} lg={4} xl={4}></Grid>
      </Grid>
    </>
  );
}

export default withTranslation()(App);
