
export const Constant = {
    fr: "fr",
    ml: "ml",
    en: "en",
    error: "FAILED",
    success: "SUCCESS",
    OrangeMoney: "OrangeMoney",
    SEVOBPay: "SEVOB-Pay",
    devis: "FCFA",
    choixAccueil: "accueil",
    modeTest: "TEST",
    codeTest: "247478",
    transctionAnnulerBack: "Transaction annulée",
    transctioneffectuerBack: "Transaction effectuée",
}