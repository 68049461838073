import { withTranslation } from "react-i18next";
import { Constant } from "../Constant";
import spinnerPrincipal from "./../assets/principal.gif";
import spinnerSecondaire from "./../assets/spinner.gif";
function Header(props) {
    const {t} = props;
    return (
        <div style={{borderBottom: "1px solid grey", paddingBottom: 10}}>
            {props.reponse.type !== Constant.error &&
                <div style={{textAlign: 'center', marginTop: -50}}>
                    {/* Spinner initialisation */}
                    {props.spinner.principal &&
                        <>
                            <img src={spinnerPrincipal} style={{borderRadius: 100, width: 100, height: 100, borderBottom: 'solid grey 2px'}}/>
                            <br/>
                            <span style={{fontWeight: 'bold', fontSize: 30}}>Un instant...</span>
                        </>
                    }
                    {/* Quand les informations sont retourné du serveur */}
                    {props.data.id !== undefined &&
                        <>
                            {/* Logo de l'entreprise */}
                                <img src={props.data.lienlogoentreprise} style={{width: 100, height: 100}}/>
                                <br/>
                            {/* Nom de l'entreprise */}
                                <span style={{fontWeight: 'bold', fontSize: 30}}>{props.data.nomentreprise}</span>
                        </>
                    }
                </div>
            }

            {/* En cas d'erreur */}
            {props.reponse.type === Constant.error &&
                <h1 style={{textAlign: 'center', color: 'red'}}>{props.reponse.message}</h1>
            }
            
            {/* Spinner Recherche */}
            {props.spinner.secondaire &&
                <div style={{textAlign: 'center', marginTop: 10}}>
                    <img src={spinnerSecondaire}/>
                    <br/>
                    <span style={{color: "red", fontWeight: 'bold'}}>Patientez s'il vous plaît...</span>
                </div>
            }
        </div>
    );
}
    export default withTranslation()(Header);