import axios from 'axios';

export const header = {'Authorization': `Basic ${localStorage.getItem('id_token') !== null ? localStorage.getItem('id_token') : ""}`};


const url = process.env.REACT_APP_BASE_URL
class Service {
      constructor() {}

      static loginApi(token) {
        const HEADERS = {'Authorization' : `Basic ${token}`};
        return axios.get(url + '/users/loginApi', {headers: HEADERS});
      }

      static getData(codeForLink) {
        return axios.get(url + `/anonyme/DataForLink/` + codeForLink);
      }

      static addTransactionTest(data) {
        return axios.post(url + `/anonyme/addTransactionTest`, data);
      }

      static SevobPay(data, token) {
        const HEADERS = {'Authorization' : `Basic ${token}`};
        return axios.post(url + `/users/SevobPay`, data, {headers: HEADERS});
      }

      static OrangeMoney(data) {
        return axios.post(url + `/anonyme/OrangeMoney`, data);
      }

}

export default Service;