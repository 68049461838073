import { Grid } from '@material-ui/core';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from '../../Constant';
import Service from '../../service';

function TestMode(props2) {
    const {t} = props2.props;
    const [value, setValue] = useState()

    const onFinish = (values) => {
        if(values.code !== Constant.codeTest) {
            toast.error(t("Code_incorrect"), {
                position: "top-center",
                autoClose: 5000,
                theme: "colored",
                })
        } else {
            props2.props.changeSpinnerSecondaire(true)
            let dataToSend = {
                typepaiement: props2.props.data.typepaiement,
                montant: props2.props.data.montant,
                nom_entreprise: props2.props.data.nomentreprise,
                autre_information: props2.props.data.autreinformation,
                notif_url: props2.props.data.successurl,
                error_url: props2.props.data.errorurl,
                notif_token: values.telephone,
                cle: props2.props.data.cle,
              }
            Service.addTransactionTest(dataToSend).then(
                async (response) => {
                    props2.props.changeReponse({
                        type: Constant.success,
                        message: ""
                    })
                    props2.props.changeSpinnerSecondaire(false)
                }, error => {
                    props2.props.changeSpinnerSecondaire(false)
                    props2.props.changeReponse({type: Constant.error, message: t("erreurServeur")})
                })
        }
      };

    return (
        <>
        <ToastContainer position='top-center'/>
        <Grid container>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
            <Grid item sm={8} md={8} lg={8} xl={8}>
                <Form
                    name="basic"
                    labelCol={{
                    span: 24,
                    }}
                    wrapperCol={{
                    span: 24,
                    }}
                    style={{
                    maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label={t("telephone")}
                        name="telephone"
                        rules={[
                            {
                            required: true,
                            message: t("champObligatoire"),
                            },
                        ]}
                    >
                        <PhoneInput
                            value={value}
                            onChange={setValue}
                            country={"ml"}
                            inputProps={{
                                name: 'telephone',
                                required: true,
                                autoFocus: true,
                              }}
                              inputStyle={{width: "100%"}}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("code")}
                        name="code"
                        rules={[
                            {
                            required: true,
                            message: t("champObligatoire"),
                            },
                        ]}
                    >
                    <Input type='number' />
                    </Form.Item>

                    <Form.Item
                    wrapperCol={{
                        offset: 0,
                        span: 16,
                    }}
                    >
                    <Button type='primary' htmlType="submit" >
                        {t("send")}
                    </Button>
                    </Form.Item>
                </Form>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
        </Grid>
        </>
    );
}
    export default withTranslation()(TestMode);